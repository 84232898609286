import axios from "axios";
import { Region } from "models/region";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { regionsReducer, initialState } from "reducers/regionsReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, Meta, setFailure, setLoading, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const RegionsContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const RegionsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(regionsReducer, initialState);

  const removeRegionById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/regions/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Region has been deleted!" })
      setTimeout(() => getRegions({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(dispatch), 5000)
    });
  }

  const getRegions = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/regions/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setRegions(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Regions"))
  }

  const setRegions = (regions: Region[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { regions: regions, meta },
    });
  };

  // const createRegion = (c: Region) => {
  //   return axios.post(`${process.env.REACT_APP_API_URL}/regions`, c)
  //     .then(res => {
  //       dispatch({ type: "actionSuccess", message: "Region has been created!" })
  //       setTimeout(() => {
  //         getRegions({ page: 1, take: 20 });
  //       }, 1000);
  //       setTimeout(() => clearActionSuccess(dispatch), 5000)
  //     },
  //       (error) => {
  //         error = error.response.data;
  //         if (error && error.errors && error.errors[0].message) {
  //           dispatch({ type: "failure", error: error.errors[0].message })
  //         } else {
  //           dispatch({ type: "failure" })
  //         }
  //         setTimeout(() => clearActionFailure(dispatch), 5000)
  //         return error;
  //       })
  // }

  // const editRegion = (c: Region) => {
  //   return axios.put(`${process.env.REACT_APP_API_URL}/regions/${c.id}`, c)
  //     .then(res => {
  //       dispatch({ type: "actionSuccess", message: "Region has been updated!" })
  //       setTimeout(() => getRegions({ page: 1, take: 20 }), 2000);
  //       setTimeout(() => clearActionSuccess(dispatch), 5000)
  //     },
  //       (error) => {
  //         error = error.response.data;
  //         if (error && error.errors && error.errors[0].message) {
  //           dispatch({ type: "failure", error: error.errors[0].message })
  //         } else {
  //           dispatch({ type: "failure" })
  //         }
  //         setTimeout(() => clearActionFailure(dispatch), 5000)
  //         return error;
  //       })
  // }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    regions: state.regions,
    meta: state.meta,
    message: state.message,
    isSuccess: state.isSuccess,
    setRegions: setRegions,
    getRegions: getRegions,
    // editRegion: editRegion,
    // createRegion: createRegion,
    removeRegionById: removeRegionById,
    setLoading,
    setFailure,
  };
  return <RegionsContext.Provider value={value}>{children}</RegionsContext.Provider>;
};

const useRegions = () => {
  const context = useContext(RegionsContext);

  if (context === undefined) {
    throw new Error("useRegions must be used within RegionsContext");
  }

  return context;
};

export default useRegions;
